@font-face{
    font-family: "NotoSans";
    src: url('../assets/fonts/NotoSans-Regular.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family: "Poppins-medium";
    src: url('../assets/fonts/Poppins-Medium.ttf');
}
@font-face{
    font-family: "NotoSansBold";
    src: url('../assets/fonts/NotoSans-Bold.woff');
    font-weight: normal;
    font-style: normal;
}