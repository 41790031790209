.Need-assistance {
  margin: 28px 0 12px 0;
  width: 100%;
  display: inline-block;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #2a343f;
}
.Contact-support {
  margin: 0 0 16px 0;
  display: inline-block;
  width: 100%;
}
.Contact-support > span {
  line-height: 20px;
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #5b646e;
  font-family: "NotoSans", sans-serif;
}
.Contact-details {
  margin-bottom: 4px;
  display: table;
  width: 100%;
}
.Contact-details > span:first-child {
  width: 8.5%;
  float: left;
}
.Contact-details > span {
  vertical-align: middle;
  font-size: 12px;
  color: #5b646e;
  font-family: "NotoSans", sans-serif;
  cursor: default;
}
.Contact-details > span > img {
  display: inline-block;
  width: 21px;
  height: 21px;
}
img {
  vertical-align: middle;
  border: 0;
}
.customer-help {
  color: #0057a6;
}
.customer-help:hover {
  color: #0057a6;
}
.contact-img {
  background-image: url("../../assets/icons/phone-logo.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 0 0 2px 28px !important;
}

.email-img {
  background-image: url("../../assets/icons/mail_logo.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 0 0 2px 28px !important;
}
.AccountPageNeedAssistance {
  width: 25%;
  background-color: white;
  padding-top: 0px !important;
  height: 626px;
  float: right;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .AccountPageNeedAssistance {
    min-width: 38% !important;
    background-color: white;
    padding-top: 0px !important;
    height: 626px;
    float: right;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .AccountPageNeedAssistance {
    min-width: 38% !important;
    background-color: white;
    padding-top: 0px !important;
    height: 626px;
    float: right;
  }
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .AccountPageNeedAssistance {
    min-width: 100% !important;
    background-color: white;
    padding-top: 0px !important;
    height: 215px;
  }
}
