.u-align-left {
  justify-content: flex-start;
}
.u-align-right {
  justify-content: flex-end;
}
.u-align-center {
  justify-content: center;
}
.u-align-justify {
  justify-content: space-between;
}

.u-align-spaced {
  justify-content: space-around;
}

.u-align-top {
  align-items: flex-start;
}
.u-align-self-top {
  align-self: flex-start;
}
.u-align-bottom {
  align-items: flex-end;
}
.u-align-self-bottom {
  align-self: flex-end;
}
.u-align-middle {
  align-items: center;
}
.u-align-self-middle {
  -ms-grid-row-align: center;
  align-self: center;
}
.u-align-stretch {
  align-items: stretch;
}
.u-align-self-stretch {
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.u-align-center-middle {
  align-content: center;
  align-items: center;
  justify-content: center;
}
