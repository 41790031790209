#applyCreditsTable {
  width: 100%;
}

#applyCreditsTable th,
#applyCreditsTable td {
  border-top: none;
}

.radio_grp input[type="radio"]:checked:before {
  display: none;
}

.right-box {
  float: left;
}

.Fields-label {
  font-size: 12px;
  color: #5b646e;
  margin-bottom: 8px;
  margin-top: 17px;
  font-family: "NotoSans", sans-serif;
  width: 100%;
  display: inline-block;
}
.ol-table-title {
  font-family: "Poppins-medium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #2a343f;
}
.order-history-right {
  background-color: #ffffff;
}

#applyCreditsTable tr {
  font-size: 12px;
}

#applyCreditsTable thead tr {
  cursor: pointer;
}
.heading-table {
  min-height: 44px;
  border: 1px solid #549cc9;
  background-color: #549cc9;
  color: #fff;
  text-shadow: none;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  font-weight: bold;
}

#applyCreditsTable td {
  display: row;
  color: #5b646e;
}

#applyCreditsTable .radio_grp {
  padding-top: 0;
}
table {
  font-family: "NotoSans", sans-serif !important;
}
.radio_grp {
  display: inline-block;
  width: 100%;
}
input[type="radio"] {
  margin-inline-start: -0.25rem;
  position: relative;
}

.radio_grp span {
  padding-left: 25px;
}

.radio_grp input[type="radio"]:after {
  display: none;
}
.Fields-input {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 #d4d9de;
  border: solid 1px #d4d9de;
  background-color: #ffffff;
  display: inline-block;
  padding: 8px 12px;
  margin-bottom: 5px;
}

.radio_grp input[type="radio"] {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 13px;
  border: 1px solid #0057a6;
  background-color: #d4d9de;
  display: inline-block;
  margin: -1px;
}

.sanofi-dropdown-sec {
  width: 100%;
}

.custom-selectpay select,
.custom-select select {
  border: 1px solid #d4d9de;
  color: #333;
  border-radius: 3px;
  background: fa fa-chevron-down;
  background-position-x: 93%;
  background-position-y: 44%;
  background-size: 15px;
  height: 28px;
  padding-top: 4px;
  margin-top: -13px;
}

#applyCreditsTable .existingDropdown .custom-selectpay {
  width: 217px;
  height: 28px;
  margin-top: 16px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) {
  #applyCreditsTable thead {
    height: 40px;
  }

  #applyCreditsTable td {
    padding: 6px 12px !important;
    height: 52px;
  }
}
