header {
  background: none;
  background-color: white;

  & .SiteLinks cx-link cx-generic-link a {
    text-decoration: none;
    min-height: 0;
  }
}


.SiteLinks {

  & cx-navigation-ui.flyout > nav > ul > li {
    position: relative;

    & > button {
      padding: 5px 0;
      margin-right: 15px;
      font-size: 11px;
      font-weight: 700;
      color: #fff;
    }

    & > .wrapper {
      padding: 15px 20px;
      box-shadow: none;

      & > nav {
        padding-right: 1.5625rem;
      }

    }
  }

  cx-navigation-ui nav ul.childs > li > cx-generic-link > a {
    font-weight: 400;
    font-size: .75rem;
    text-decoration: none;
    color: #0057a6;
    border-bottom: 0.0625rem solid #edf6fb;
    padding: 0.5625rem 0 0.625rem;
  }
}

.navigation {
  cx-navigation-ui.flyout nav ul li {
    position: relative;

    & > cx-generic-link + button {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      flex-flow: row-reverse;
    }
  }
}

header .cx-hamburger.is-active .hamburger-inner {
  &,
  &::before,
  &::after {
    background-color: #fff;
  }
}

@media (max-width: 768px) {
  cx-navigation-ui.flyout.is-open li.is-open {
    background-color: #f4f4f4;
  }
}

.header .NavigationBar {

  cx-navigation-ui.flyout > nav > ul > li {
    position: relative;
    margin-right: 50px;
    margin-top: 3px;

    & > button {
      position: absolute;
      width: 100%;
      display: block;
      text-align: right;
      padding-top: 22px;
      padding-bottom: 12px;
    }
  }

  cx-navigation-ui {
    background-color: transparent;

    & cx-generic-link {

      & a {
        text-transform: none;
        font-family: Poppins-medium, sans-serif;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    & > nav > ul > li > .wrapper {
      border: none;
      box-shadow: 0 2px 3px 0 rgba(23, 114, 183, .2);
      padding: 3px 16px;
      margin: -12px -18px;
      margin-top: 52px;

      & a {
        border-bottom: 1px solid #edf6fb;
        padding: 10px 0;
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 991.98px) {
  header .NavigationBar {
    background-color: #f4f4f4;
  }
}

.NavigationBar cx-navigation-ui.flyout {
  cx-generic-link a,
  .back button {
    font-size: 16px;
  }

  .wrapper cx-generic-link a {
    font-size: 12px;
  }
}

.BottomHeaderSlot cx-breadcrumb {
  box-shadow: none;

  & a {
    color: #337ab7;
    min-height: 0;
  }
}

.existing-user a {
  min-height: 0;
}

.cart-icons {
  & a {
    display: inline;
  }
}


header.is-expanded {
  .header,
  .navigation {
    background-color: #212738;
  }

  @media (max-width: 768px) {
    .MedeoLogo {
      z-index: -1;
    }
  }
}


@media (min-width: 768px) {
  header.is-expanded {
    .header,
    .navigation {
      background-color: transparent;
    }

    .noDisplay {
      &.SiteLinks,
      &.SiteContext {
        display: flex !important;
      }

      &.SiteLogo,
      &.MiniCart,
      &.SearchBox {
        display: block !important;
      }
    }
  }
}

