:root {
  --lib-base-font-size: 12px;
}

$size-none: 0;
$size-hairline: 1px;
$size-border: 0.1em;
$size-xxs: 0.25em;
$size-xs: 0.5em;
$size-s: 0.75em;
$size-n: 1em;
$size-l: 1.5em;
$size-xl: 2em;
$size-xxl: 4em;
