cx-page-layout.LandingPage2Template {
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .ad-files-text {
    display: flex;
    justify-content: end;

    a {
      min-height: 0;
    }
  }


  #continueShoppingId {
    margin: 0;
  }

  section.preheader > section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    section.header-links {
      width: auto;
    }

    section.float-left {
      float: none !important;

      &.middle-row {
        margin-bottom: 2px;
      }
    }

    a {
      min-height: 0;
      min-width: 0;
      display: flex;
      align-items: center;
    }
  }

  .save-document {
    display: flex;
    justify-content: flex-end;
  }

  .terms-Conditions,
  .switch-whs-popup {

    a {
      min-height: 0;
      display: inline;
    }
  }

  .row.cart-price-data,
  section.edit-saved-cart .row.add-to-cart {
    .actionOptions,
    .actionOptions-small {
      display: flex;
      justify-content: space-around;
      gap: 30px;
      padding: 0;

      a {
        min-height: 0;
      }

      a#linkViewEdit {
        padding: 0;
      }
    }
  }


  #applyStatementsTable {
    tr td a {
      display: inline;
    }
  }

  section.details-header {
    .middle-row {
      a {
        display: inline;
      }
    }

    section.save-document {
      margin-bottom: 0 !important;
      max-width: none;
    }
  }

  section.row .delete-popover-container-rwd {
    margin: 24px 12px;
    text-align: left;
  }
  cx-page-slot.EmptyCartMiddleContent .sticky {
    max-width: unset;

    .sticky-total {
      max-width: unset;
    }
  }
}

@media only screen and (min-width: 992px) {
  div.modal-dialog {
    width: fit-content;
    max-width: 1280px;
  }
}

.select-account-popup .itc-inner-cont .cart-modal-footer .btn {
  font-weight: var(--cx-font-weight-normal);

  .credits-table tr td {
    a {
      min-height: 0;
    }
  }
}

.standart-cart section#leftCont-rightNav-cart {
  margin-left: 0;
  margin-right: 0;
}

.sticky-total h3 {
  text-wrap: initial;
}

cx-page-layout.header .SearchBox cx-searchbox {
  position: relative;
  z-index: 8;

  label {
    width: auto;
  }
}

.CartPageTemplate {
  max-width: unset;
}

.popover.popover-links {
  button {
    min-height: 32px;
  }
}

.popover.popover-links.saveorder-incart {
  h3.popover-header {
    text-align: left;
    padding: 20px 0 10px;
  }
}

cx-active-facets {
  align-items: center;

  div:first-child {
    font-size: 12px;
    color: #5b646e;
    flex: initial;
    margin: 0 10px 0 0;
    padding: 0;
  }

  a {
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
}

.modal {
  background: none!important;
}

section.quick-prod-details {
  padding-left: 0!important;

  .row {
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;

    .price-details {
      padding: 0!important;
    }

    .quickorder-button {
      margin: 0;
    }

    .units {
      width: auto;
    }

    &:before,
    &:after {
      content: none;
    }
  }
}
