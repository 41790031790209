

@font-face{
  font-family: NotoSans;
  src: url('../../assets/fonts/NotoSans-Regular.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: Poppins-medium;
  src: url('../../assets/fonts/Poppins-Medium.ttf');
}

@font-face{
  font-family: NotoSansBold;
  src: url('../../assets/fonts/NotoSans-Bold.woff');
  font-weight: normal;
  font-style: normal;
}

@mixin brand-font() {
  font-family: NotoSans, sans-serif;
}

@mixin brand-font-bold() {
  font-family: NotoSansBold, sans-serif;
}

@mixin poppins-font() {
  font-family: Poppins-medium, sans-serif;
}

//h1,
//.h1 {
//  @include brand-font();
//  letter-spacing: rem-calc(1px);
//
//  @include breakpoint(large) {
//    letter-spacing: rem-calc(1.3px);
//  }
//
//  @include breakpoint(xxlarge) {
//    letter-spacing: rem-calc(1.5px);
//  }
//}
//
//h2,
//.h2 {
//  @include brand-font();
//  font-weight: bold;
//  letter-spacing: inherit;
//}
//
//h3,
//.h3 {
//  @include brand-font();
//  font-weight: bold;
//  letter-spacing: inherit;
//}
//
//h4,
//.h4 {
//  @include brand-font();
//  letter-spacing: inherit;
//}
//
//h5,
//.h5 {
//  @include brand-font();
//  font-weight: bold;
//  letter-spacing: inherit;
//}
//
//h6,
//.h6 {
//  @include brand-font();
//  letter-spacing: inherit;
//}
