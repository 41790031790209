cx-footer-navigation,
cx-footer-navigation cx-navigation-ui {
  background: none;
}

cx-footer-navigation cx-navigation-ui span {
  text-transform: capitalize;
  font-family: NotoSansBold, sans-serif;
  font-size: .875rem;
  color: #73aed3;
  margin-bottom: 1.25rem;
  padding: 0;
}
cx-page-layout.footer cx-page-slot cx-footer-navigation cx-navigation-ui > nav{
  border-left: none;
  height: auto;
  padding: 0;

  & > ul > li {
    outline: none;
    padding: 0px 20px 0px 25px;
    border-left: 1px solid #13181d;
    //height: 245px;
    height: auto;
    margin: 0;

    & .childs > li {
      margin-bottom: 15px;

      & a {
        font-family: NotoSans, sans-serif;
        font-size: 14px;
        min-height: 0;
      }
    }
  }
}
