.order-info p {
  font-family: "NotoSansBold", sans-serif;
  display: block;
  color: #5b646e;
  text-align: center;
}
.order-info span {
  margin-top: 4px;
  padding-right: 0px;
  color: #5b646e;
  font-family: "NotoSans", sans-serif;
  text-align: center;
  display: block;
}
.email-id {
  color: #0057a6 !important;
  word-wrap: break-word;
  font-size: 11px !important;
}
.email-id:hover {
  text-decoration: underline !important;
  color: #05579d !important;
}
.order-info .order-details a:hover {
  text-decoration: underline !important;
  color: #05579d !important;
}
.contact-logo {
  background-image: url(../../assets/icons/phone-logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 21px 21px 2px 28px !important;
}
.email-logo {
  background-image: url(../../assets/icons/mail_logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 21px 21px 2px 28px !important;
}
