@use "spartacus";
@use '../library' as *;
@use "meta" as *;

:root {
  --lib-color-link: #0057a6;
  --lib-color-link-hover: #0057a6;
  --lib-background-body: #edf6fb;
  --lib-color-body: #5b646e;
  --lib-color-primary: #0057a6;
  --lib-color-white: #fff;
  --lib-background-box-title: #73aed3;
  --lib-color-box-title: #fff;
  --lib-color-form-label:#5b646e;
  --lib-color-form-input-border:#d4d9de;
  --lib-color-form-input-shadow:#d4d9de;


  --lib-base-font-size: 12px;
}
