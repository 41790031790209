.RegistrationPageTemplate,
.RegisterPageTemplate,
.CustomerRegisterPageTemplate {
  display: flex;
  width: 100%;
  padding: 0px;
}

.RegistrationPageTemplate .NeedAssistanceSlot,
.CustomerRegisterPageTemplate .NeedAssistanceSlot,
.RegisterPageTemplate .UserRegisterBannerSlot {
  max-width: 25%;
  display: block !important;
  background: white;
  margin: 0px;
  padding: 25px;
}
.RegistrationPageTemplate .CustomerRegistrationSlot,
.CustomerRegisterPageTemplate .CustomerRegistrationSlot,
.RegisterPageTemplate .RegistrationSlot {
  padding: 0px !important;
}

.RegisterPageTemplate
  .RegistrationSlot
  app-account-registration
  .registrationView
  .account-reg-section {
  flex: none !important;
  max-width: 100%;
  display: block;
}
@media (min-width: 1280px) {
  app-account-registration .registrationView {
    width: 100%;
  }
  app-user-registration .registrationView {
    width: 100%;
  }
}

.fluseasonreservationpagetemplate .BannerContent {
  position: relative;
  left: 75.8%;
  width: 22%;
  bottom: 18rem;
  display: inline-block;
}

//forgot password module
.LoginHelpPageTemplate {
  display: flex;
}
.LoginHelpPageTemplate .BodyContent {
  background: #fff;
  width: 75%;
  max-width: 75%;
  border-right: 4px solid #edf6fb !important;
}
.LoginHelpPageTemplate .NeedAssistanceSlot {
  width: 25%;
  max-width: 25%;
  background: #fff;
  padding: 0px 0px 0px 30px;
}

.RegisterConfirmPageTemplate {
  display: flex;
  .UserRegisterBannerSlot {
    display: inline-block;
    width: 25%;
    flex: none !important;
    background: white;
    padding: 20px;
  }
}

.CustomerRegisterConfirmPageTemplate {
  display: flex;
}
.CustomerRegisterConfirmPageTemplate .RegistrationSlot {
  display: block;
  max-width: 100%;
  width: 100%;
}
.CustomerRegisterConfirmPageTemplate .UserRegisterBannerSlot {
  display: flex;
  width: 25%;
  max-width: 25%;
  padding: 20px;
  background: #fff;
}

@media screen and (min-width: 360px) and (max-width: 640px) and (orientation: portrait) {
  .LoginHelpPageTemplate {
    display: block;
  }
  .LoginHelpPageTemplate .BodyContent {
    background: #fff;
    width: 100%;
    min-width: 100%;
    border-bottom: 4px solid #edf6fb !important;
  }
  .LoginHelpPageTemplate .NeedAssistanceSlot {
    width: 100%;
    min-width: 100%;
    background: #fff;
    padding: 0px 0px 0px 15px;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .LoginHelpPageTemplate {
    display: block;
  }
  .LoginHelpPageTemplate .BodyContent {
    background: #fff;
    width: 100%;
    min-width: 100%;
    border-bottom: 4px solid #edf6fb !important;
  }
  .LoginHelpPageTemplate .NeedAssistanceSlot {
    width: 100%;
    min-width: 100%;
    background: #fff;
    padding: 0px 0px 0px 15px;
  }
  .RegisterConfirmPageTemplate {
    display: block;
  }
  .RegistrationPageTemplate,
  .RegisterPageTemplate,
  .CustomerRegisterPageTemplate,
  .CustomerRegisterConfirmPageTemplate {
    display: block;
  }
  .RegistrationPageTemplate .NeedAssistanceSlot,
  .CustomerRegisterPageTemplate .NeedAssistanceSlot {
    max-width: 100%;
  }
  .CustomerRegisterConfirmPageTemplate .UserRegisterBannerSlot {
    max-width: 100%;
    width: 100%;
  }
  .RegisterConfirmPageTemplate .UserRegisterBannerSlot {
    display: inline-block;
    width: 100%;
    flex: none !important;
    background: white;
    padding: 20px 0px 20px 36px;
  }
  .fluseasonreservationpagetemplate .BannerContent {
    position: relative;
    width: 100%;
    margin: -35px 00px 0px 0px;
    padding: 37px;
    display: block;
    left: 0px;
    background-color: #ffffff;
  }
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .RegistrationPageTemplate .NeedAssistanceSlot,
  .CustomerRegisterPageTemplate .NeedAssistanceSlot,
  .RegisterPageTemplate .UserRegisterBannerSlot {
    max-width: 28%;
  }
  .fluseasonreservationpagetemplate .BannerContent {
    position: relative;
    left: 0px;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 20px;
    margin: -37px 0px 0px 0px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .LoginHelpPageTemplate {
    display: flex;
  }
  .LoginHelpPageTemplate .BodyContent {
    background: #fff;
    width: 70%;
    min-width: 70%;
    border-right: 4px solid #edf6fb !important;
  }
  .LoginHelpPageTemplate .NeedAssistanceSlot {
    width: 30%;
    min-width: 30%;
    background: #fff;
    padding: 0px 0px 0px 20px;
  }
  .RegisterConfirmPageTemplate,
  .RegistrationPageTemplate,
  .CustomerRegisterPageTemplate {
    display: flex !important;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot2 {
    padding: 20px 20px 40px 20px;
    width: 30.3% !important;
  }
  .CustomerRegisterConfirmPageTemplate {
    display: flex !important;
  }
  .CustomerRegisterConfirmPageTemplate .UserRegisterBannerSlot {
    width: 25% !important;
    max-width: 25% !important;
  }
  .RegistrationPageTemplate .NeedAssistanceSlot,
  .RegisterPageTemplate .UserRegisterBannerSlot,
  .CustomerRegisterPageTemplate .NeedAssistanceSlot {
    max-width: 30%;
  }
  .RegisterConfirmPageTemplate .UserRegisterBannerSlot,
  .CustomerRegisterConfirmPageTemplate .UserRegisterBannerSlot {
    width: 25% !important ;
    padding: 20px !important;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot6 {
    height: 507px;
    position: relative;
    padding: 200px 15px 15px 15px;
    width: 28%;
    margin: 5px 0px 0px 0px;
  }
}

//news width full
.LoginPageTemplate .AccountOverviewFinancialsSlot app-account-overview {
  display: flex;
}

.LoginPageTemplate .SanofiAccountOverviewBannerSlot {
  display: block;
  width: 100%;
  margin: 0px !important;
  padding: 20px 20px 0px 20px;
  max-width: 100%;
  cx-paragraph {
    flex: none !important;
    max-width: 100%;
    width: 100%;
    p {
      margin-bottom: 0px;
    }
    .header-close {
      width: 16px;
      height: 16px;
      float: right;
      margin-top: 13px;
      cursor: pointer;
    }
  }
}

.LoginPageTemplate .NewsAndUpdatesSlot {
  display: inline-block;
  background: #fff;
  max-width: 32.4%;
  padding: 30px;
  width: 32.4%;
  float: right;
  margin: 5px 18px 0px 0px;
  cx-paragraph {
    max-width: 100%;
  }
}
.LoginPageTemplate .SanofiAccountOverviewBannerSlot6 {
  display: inline-block;
  background: #fff;
  padding: 147px 20px 124px 20px;
  width: 28.2%;
  float: left;
  margin: 5px 0px 0px 0px;
}

.LoginPageTemplate .AccountOverviewPlaceholderSlot {
  display: inline-block;
  background: #fff;
  max-width: 64.2%;
  width: 64.2%;
  margin: 5px 0px 0px 21px;
  float: left;
}
.LoginPageTemplate
  .AccountOverviewPlaceholderSlot2
  app-acc-overview-placeholder1
  app-flu-content
  .line-bottom {
  border-bottom: 0px solid #edf6fb !important;
}
.LoginPageTemplate
  .SanofiAccountDashboardFluReservationsCMSSlot
  app-flu-content
  .displayNo {
  border-right: 4px solid #edf6fb !important;
}
.LoginPageTemplate .SanofiAccountOverviewBannerSlot5 {
  display: inline-block;
  width: 32.5%;
  max-width: 32.5%;
  flex: none !important;
  background-color: #fff;
  padding: 55px;
  float: right;
  margin: 5px 17px 0px 0px;
}
.LoginPageTemplate .AccountOverviewPlaceholderSlot2 {
  display: inline-block;
  background: #fff;
}
.LoginPageTemplate
  .AccountOverviewPlaceholderSlot2
  app-acc-overview-placeholder1
  app-flu-content
  .mini-card {
  flex: none !important;
  max-width: 100%;
  width: 100%;
  margin: 0px 0px 0px 0px;
}

.LoginPageTemplate
  .AccountOverviewFinancialsSlot
  app-account-overview
  > section
  app-financials-list
  .invoice-btngrps {
  margin: 0px 0px 30px 0px;
}
.LoginPageTemplate .AccountOverviewOrdersSlot {
  display: inline-block;
  margin: 0px 4px 0px 22px;
  padding: 0px;
  width: 64.1%;
  float: left;
  app-account-overview {
    flex: none !important;
    max-width: 100%;
    min-width: 100%;
  }
  min-height: 1200px;
  background-color: #fff;
  border-bottom: 4px solid #edf6fb;
}
.LoginPageTemplate .AccountOverviewPlaceholderSlot2 {
  width: 32.5%;
  padding: 0px;
  float: right;
  app-acc-overview-placeholder1 aside {
    flex: none;
    max-width: 100%;
    min-width: 100%;
    padding: 0px;
  }
  max-width: 32.5% !important;
  width: 30.5% !important;
  min-width: 30.5% !important;
  margin: 0px 15px 0px 0px;
}
.LoginPageTemplate .SanofiAccountOverviewBannerSlot2 {
  display: inline-block;
  padding: 55px;
  background: #fff;
  margin: 5px 17px 0px 0px;
  width: 32.5%;
  float: right;
}

.LoginPageTemplate cx-page-slot.AccountOverviewFinancialsSlot.has-components {
  background: white;
  flex: none !important;
  max-width: 64.5%;
  width: 64.1%;
  padding: 20px;
  margin: 0px 1px 0px 22px;
  display: inline-block;
  float: left;
  min-height: 1200px;
  app-financials-list {
    flex: none !important;
    max-width: 100%;
    padding: 20px;
  }
}
.LoginPageTemplate
  .AccountOverviewPlaceholderSlot2
  app-acc-overview-placeholder1
  app-order-products
  app-quick-order
  .dosessection {
  padding: 0px 0px 0px 0px;
}
.LoginPageTemplate
  .AccountOverviewPlaceholderSlot2
  app-acc-overview-placeholder1
  aside
  section
  app-order-products
  app-quick-order
  cx-searchbox
  .searchbox {
  max-width: 364px;
  min-width: 364px;
  width: 364px;
}
.LoginPageTemplate
  .SanofiAccountDashboardFluReservationsCMSSlot
  app-flu-content
  .banner6 {
  display: none;
}
.LoginPageTemplate .SanofiAccountDashboardFluReservationsCMSSlot {
  background: #fff;
  flex: none !important;
  max-width: 36%;
  width: 36%;
  padding: 20px;
  display: inline-block;
  float: left;
  flex: 1 0 37%;
  margin: 5px 0px 0px 21px;
  app-flu-content section.line-bottom {
    flex: none !important;
    max-width: 100%;
    width: 100%;
    border-bottom: 0px solid #edf6fb;
    .mini-card {
      flex: none !important;
      max-width: 100%;
      width: 100%;
      border-right: 0px solid #edf6fb;
    }
  }
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .LoginHelpPageTemplate .BodyContent {
    background: #fff;
    width: 70%;
    min-width: 70%;
    border-right: 4px solid #edf6fb !important;
  }
  .LoginHelpPageTemplate .NeedAssistanceSlot {
    width: 30%;
    min-width: 30%;
    background: #fff;
    padding: 0px 0px 0px 20px;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-flu-content
    .mini-card {
    border-right: 0px solid #edf6fb !important;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-flu-content
    .mini-card
    .three-columns-line
    button {
    position: absolute;
    left: 25%;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    aside
    section
    app-order-products
    app-quick-order
    cx-searchbox
    .searchbox {
    max-width: 324px;
    min-width: 236px !important;
    width: 234px !important;
  }
  .LoginPageTemplate .AccountOverviewOrdersSlot {
    width: 63.5%;
    min-height: 1094px !important;
  }
  .LoginPageTemplate .NewsAndUpdatesSlot {
    margin: -2px 6px 0px 0px;
    max-width: 93.7%;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot6 {
    padding: 147px 20px 207px 20px !important;
    width: 27.2% !important;
  }

  .LoginPageTemplate .AccountOverviewPlaceholderSlot {
    max-width: 94%;
    margin: 0px 0px 0px 24px;
  }
  .LoginPageTemplate .SanofiAccountDashboardFluReservationsCMSSlot {
    margin: 5px 0px 4px 21px;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot5 {
    padding: 20px 15px 40px 15px;
    width: 46.9%;
    text-align: center;
    margin: 5px 5px 0px 0px;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot2 {
    display: inline-block;
    width: 33.1%;
    padding: 17px;
    margin: 5px 0px 0px 0px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-flu-content
    .mini-card {
    border-right: 0px solid #edf6fb !important;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-order-products
    app-quick-order
    .dosessection {
    text-align: center;
    width: 76%;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    aside
    section
    app-order-products
    app-quick-order
    cx-searchbox
    .searchbox {
    max-width: 364px;
    min-width: 302px;
    width: 302px;
  }
  .LoginPageTemplate .AccountOverviewOrdersSlot {
    width: 63.1%;
  }
  .LoginPageTemplate cx-page-slot.AccountOverviewFinancialsSlot.has-components {
    width: 63.1%;
    min-height: 984px !important;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot6 {
    padding: 127px 20px 146px 20px;
    width: 27.2%;
  }
  .LoginPageTemplate .AccountOverviewPlaceholderSlot {
    max-width: 63.1;
    max-width: 63.1%;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-flu-content
    .mini-card
    .three-columns-line
    button {
    margin: 90px 0px 0px 196px;
    position: absolute;
    left: 25%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-flu-content
    .mini-card {
    border-right: 0px solid #edf6fb !important;
  }
  .LoginPageTemplate .AccountOverviewOrdersSlot {
    width: 64% !important;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    aside
    section
    app-order-products
    app-quick-order
    cx-searchbox
    .searchbox {
    min-width: 210px !important;
    width: 233px !important;
    margin: 0px -5px 0px -28px;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot6 {
    height: 507px !important;
    position: relative;
    padding: 200px 15px 15px 15px !important;
    width: 28% !important;
    margin: 5px 0px 0px 0px !important;
  }

  .LoginPageTemplate .NewsAndUpdatesSlot {
    max-width: 31.4% !important;
    padding: 30px;
    width: 30.1% !important;
    margin: 3px 18px 0px 0px;
  }
  .LoginPageTemplate cx-page-slot.AccountOverviewFinancialsSlot.has-components {
    max-width: 64.5% !important;
    width: 64.1% !important;
  }
  .LoginPageTemplate .SanofiAccountDashboardFluReservationsCMSSlot {
    max-width: 36% !important;
    width: 36% !important;
  }
  .LoginPageTemplate .AccountOverviewPlaceholderSlot {
    max-width: 64.2% !important;
    width: 64.2% !important;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-flu-content
    .mini-card
    .three-columns-line
    button {
    position: absolute;
    left: 27%;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot5 {
    width: 30.5% !important;
    max-width: 30.5% !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot5,
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot2 {
    padding: 20px 20px 40px 20px;
    width: 95%;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-order-products
    app-quick-order
    .dosessection {
    padding: 0px 0px 0px 0px;
    width: 100%;
    text-align: center;
  }

  .LoginPageTemplate
    .SanofiAccountOverviewBannerSlot6
    cx-banner
    cx-generic-link {
    padding: 0px 0px 0px 0px !important;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot6 {
    height: auto;
    position: relative;
    padding: 50px;
    width: 90%;
    margin: 20px;
  }
  .LoginPageTemplate
    .AccountOverviewFinancialsSlot
    app-account-overview
    .my-acc-aside {
    display: none;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot
    app-account-overview
    app-flu-content
    .displayIphone {
    display: none !important;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot
    app-account-overview
    app-flu-content
    .displayNo {
    display: block !important;
  }
  .fluseasonreservationpagetemplate .BannerContent {
    position: relative;
    left: 0px;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 20px;
    margin: -37px 0px 0px 0px;
  }
  .LoginPageTemplate .AccountOverviewOrdersSlot {
    width: 90%;
  }
  .LoginPageTemplate .NewsAndUpdatesSlot,
  .LoginPageTemplate cx-page-slot.AccountOverviewFinancialsSlot.has-components,
  .LoginPageTemplate .AccountOverviewPlaceholderSlot2,
  .LoginPageTemplate .AccountOverviewPlaceholderSlot,
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot5,
  .LoginPageTemplate .SanofiAccountDashboardFluReservationsCMSSlot {
    max-width: 90%;
    width: 90%;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot5 {
    max-width: 90%;
    width: 90%;
    margin: 5px 15px 0px 0px;
  }
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot2 {
    margin: 5px 14px 0px 2px;
    max-width: 90%;
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .LoginPageTemplate .SanofiAccountOverviewBannerSlot6 {
    height: 581px;
  }
  .LoginPageTemplate .SanofiAccountDashboardFluReservationsCMSSlot {
    min-height: 581px;
  }
  .LoginPageTemplate
    .AccountOverviewPlaceholderSlot2
    app-acc-overview-placeholder1
    app-order-products
    app-quick-order
    .dosessection {
    padding: 0px 26px 0px 0px;
    text-align: center;
  }
}

@import "smart-edit/home-page-banner-component.scss";
@import "smart-edit/se-faq.scss";
