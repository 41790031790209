.MedicalResourceComp .medicalResourceSection .product-details .title {
  color: #337ab7 !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-family: "NotoSansBold", sans-serif !important;
  font-weight: 100 !important;
}
.sanofiMediacalResourcesPagetemplate
  .MedicalResourceComp
  .medicalResourceSection {
  background-color: #ffffff !important;
  padding: 0px !important;
  border-right: 4px solid #ffffff !important;
}
.sanofiResourcesPageTemplate {
  display: flex;
  width: 100%;
}
.MedicalResourceComp .medicalResourceSection .product-details {
  list-style: none;
  padding: 0;
  margin: 0;
}
.MedicalResourceComp .medicalResourceSection .product-details .content-desc {
  color: #5b646e;
  font-size: 14px;
  font-family: "NotoSans", sans-serif;
  padding: 27px 0 26px 0;
  margin-bottom: 0;
}
.medicalResourceSection .product-details li h3 {
  font-weight: 100;
  line-height: 1.2222222222;
  margin-top: 20px;
  margin-bottom: 10px;
}
.MedicalResourceComp .medicalResourceSection .product-details a {
  color: #337ab7;
  text-decoration: none;
  font-size: 14px !important;
  font-family: "NotoSansBold", sans-serif;
  background-image: url("../../assets/icons/external-link.svg") !important;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 0 15px 2px 28px !important;
  margin-left: -28px;
}
.MedicalResourceComp .medicalResourceSection .product-details .title a {
  color: #337ab7;
  text-decoration: none;
  font-size: 14px !important;
  font-family: "NotoSansBold", sans-serif;
  background-image: url("../../assets/icons/external-link.svg") !important;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 0 15px 2px 28px !important;
  margin-left: -28px;
}
.MedicalResourceComp .medicalResourceSection .product-details .title a:hover {
  text-decoration: underline !important;
  color: #05579d !important;
  font-size: 14px !important;
  font-family: "NotoSansBold", sans-serif;
  background-image: url("../../assets/icons/external-link.svg") !important;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 0 15px 2px 28px !important;
  margin-left: -28px;
}
.MedicalResourceComp .medicalResourceSection .product-details li {
  border-bottom: 1px solid #edf6fb;
}
#leftCont-rightNav > section.left-section {
  padding: 0 32px;
  border-right: 4px solid #edf6fb;
}
.MedicalResourceComp {
  float: left;
  width: 70%;
  background-color: white;
  padding-left: 20px;
  border-right: 4px solid #edf6fb;
  min-height: 930px;
  padding-top: 10px;
}
.MedicalResourceComp a:hover {
  text-decoration: underline !important;
  color: #05579d !important;
  font-weight: 100 !important;
}
.MedicalResourceComp li {
  border-bottom: 1px solid #edf6fb;
}
.MedicalResourceComp > section.left-section {
  padding: 0 32px;
  border-right: 4px solid #edf6fb;
}

.healthcarevertical {
  background-color: white;
  display: grid;
  align-self: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.healthcarehorizantal {
  background-color: white;
  display: grid;
  align-self: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) and (orientation: portrait) {
  .MedicalResourceComp {
    width: 100%;
    background-color: white;
    padding-left: 20px;
    float: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .healthcarevertical {
    background-color: white;
    display: grid;
    align-self: center;
    padding: 20px !important;
  }
}
