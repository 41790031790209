// https://colors.artyclick.com/color-name-finder/

:root {

  --lib-background-body: default;
  --lib-color-body: default;

  --lib-background-box-title: default;
  --lib-color-box-title: default;

  --lib-color-link:default;
  --lib-color-link-hover:default;
  --lib-color-primary:default;

  --lib-color-form-title:default;
  --lib-color-form-label:default;
  --lib-color-form-input-border:default;
  --lib-color-form-input-shadow:default;

}
