@use "meta" as *;
@use "../library" as *;

$box-shadow-header: rgb(23 114 183 / 20%);

header {
  box-shadow: rem-calc(0 0 5px) $box-shadow-header;
  z-index: 9;
  background-color: $color-white;
}

cx-page-layout {
  a:hover {
    color: $color-medium-teal-blue;
  }

  &.NavigationBar {
    background-color: $color-ebony-clay;
  }

  &.header {
    background-color: $color-white;
    max-width: rem-calc(1360px);
    margin: 0 auto;
    padding: rem-calc(0 22px 22px 22px);
    display: flex;

    .childs > nav > cx-generic-link > a {
      @include brand-font();
      font-size: rem-calc(12px);
      text-decoration: none;
      color: $color-medium-teal-blue;
      font-weight: normal;
      border-bottom: rem-calc(1px) solid $color-catskill-white;
      padding: rem-calc(9px 0 10px);

      &:hover {
        color: $color-medium-teal-blue;
      }
    }

    cx-page-slot {
      &.SiteLogo {
        width: rem-calc(200px);
        padding: rem-calc(20px 0 0 0);

        & > cx-banner cx-generic-link a .is-initialized {
          width: rem-calc(330px);
        }
        flex: 100%;
        margin-bottom: rem-calc(15px);

        @include breakpoint(large) {
          flex: 50%;
        }

        @include breakpoint(xxlarge) {
          flex: 25%;
        }
      }

      &.MiniCart {
        flex: 100%;
        text-align: left;
        margin-bottom: rem-calc(35px);

        @include breakpoint(large) {
          flex: 50%;
        }

        @include breakpoint(xxlarge) {
          flex: 20%;
          text-align: right;
          padding: rem-calc(50px 25px 0px 0px);
        }
      }

      &.MedeoLogo {
        flex: 100%;
        margin-bottom: rem-calc(15px);

        @include breakpoint(large) {
          flex: 50%;
        }

        @include breakpoint(xxlarge) {
          flex: 24%;
          margin-bottom: rem-calc(0);
          padding: rem-calc(20px 0 0 0);
        }
      }

      &.SearchBox {
        flex: 100%;

        @include breakpoint(large) {
          flex: 50%;
        }

        @include breakpoint(xxlarge) {
          flex: 24%;
          padding: rem-calc(20px 0 0 0);
        }
      }

      &.SiteLinks {
        flex: 100%;
        display: flex;
        font-size: rem-calc(11px);
        color: $color-white;
        background-color: $color-gunmetal;
        margin: rem-calc(0 -22px 20px -22px);
        padding: rem-calc(5px 15px);
        align-items: center;
        justify-content: flex-end;
        min-height: rem-calc(40px);

        cx-link {
          border-top: 0;
        }

        cx-navigation-ui nav {
          display: none;

          @include breakpoint(xlarge) {
            display: block;
            padding-right: rem-calc(25px);
          }
        }

        cx-navigation cx-navigation-ui.flyout > nav > h5 {
          font-size: rem-calc(11px);
          font-weight: 700;
          padding: rem-calc(0 15px);
          color: $color-white;
          display: flex;
          align-items: center;
        }

        cx-link cx-generic-link a {
          width: auto;
          color: $color-white;
          font-size: rem-calc(11px);
          font-weight: 700;

          &:hover {
            text-decoration: none;
            color: $color-white;
          }
        }
      }

      &.NavigationBar {
        flex: 100%;
        margin-bottom: rem-calc(-35px);

        cx-navigation-ui.flyout h5 {
          @include poppins-font();
          text-transform: capitalize;
          font-size: rem-calc(16px);
          padding-inline-end: 50px;

          &:hover {
            color: $color-medium-teal-blue;
          }
        }
      }
    }

    cx-category-navigation {
      color: $color-medium-teal-blue;
      .flyout {
        .wrapper {
          padding: rem-calc(3px 16px);
          margin: rem-calc(-12px -18px);
          box-shadow: rem-calc(0 2px 3px 0px) $box-shadow-header;
          border: none;
          transition: opacity 0.4s;

          cx-generic-link {
            padding: rem-calc(7px 0);

            a {
              font-size: rem-calc(14px);
              margin-left: rem-calc(40px);
              @include poppins-font();
              color: $color-medium-teal-blue;

              &:hover {
                color: $color-medium-teal-blue;
              }
            }
          }
        }
      }
    }

    .SearchBox {
      margin: 0;

      cx-searchbox label {
        height: rem-calc(44px);
        border-radius: rem-calc(4px);
        width: rem-calc(388px);
      }
    }

    .MedeoLogo {
      cx-media {
        width: rem-calc(200px);
      }
    }

    .NavigationBar {
      flex: 100%;
    }

    /** Searchbox */
    cx-searchbox > * {
      border: rem-calc(1px) solid $color-iron;
      box-shadow: inset rem-calc(0 0 2px 0) $color-iron;
      background-color: $color-white;
    }
  }
}

cx-breadcrumb {
  h1 {
    font-size: rem-calc(32px);
    white-space: normal;
    overflow: hidden;
    margin: rem-calc(0px 0px 0px 5px);
    text-overflow: ellipsis;
    text-align: left;
    padding: rem-calc(0px 0px 19px 0px);
    color: $color-bluish;
    @include poppins-font();
  }

  nav span a:hover {
    color: $color-bluish;
    cursor: pointer;
    text-decoration: underline;
  }

  nav span a header .SiteLogin {
    display: none;
  }

  nav {
    padding: rem-calc(20px 12px 20px 0px);
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-bluish;
    font-size: rem-calc(11px);
    @include brand-font();
  }
}

.BottomHeaderSlot cx-breadcrumb nav span {
  color: $color-bluish;
}

.noDisplay {
  display: none !important;
}

//this should be moved to another file (quick-order)
app-quick-order cx-searchbox label {
  width: 100%;
  border: rem-calc(1px) solid $color-iron;
  box-shadow: inset rem-calc(0 0 2px 0) $color-iron;
}
