.faq-container {
  display: flex;
  align-items: stretch;
  background-color: #fff;
  flex-direction: column;
  padding: 0 40px 40px;
  h2 {
    font-size: 20px;
  }
}
.child-container-one {
  flex-grow: 6;
}
.child-container-two {
  flex-grow: 4;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.parent .btn-link {
  text-decoration: none;
  font-size: 25px;
  vertical-align: middle;
}
::ng-deep .parent.accordion > .card {
  border: 0px solid rgba(0, 0, 0, 0.125);
}
::ng-deep .parent.accordion > .card > .card-header {
  background-color: rgba(255, 255, 255, 1) !important;
  color: #000 !important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125) !important;
}

.accordion {
  .card {
    margin-bottom: 0;
    border: none;
    &-header {
      background-color: white;
      color: #000;
      padding: 0px;
      border: none;
      h5 {
        font-size: $font-size-base * 1;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        font-weight: 200;
        color: #000;
        .btn {
          text-align: start;
          text-decoration: none !important;
          width: 100%;
          font-weight: 600;
          &:hover {
            text-decoration: none;
          }
          &:after {
            float: right;
            font-size: 33px;
            bottom: 12px;
            position: relative;
          }
          &[aria-expanded="false"] {
            &:after {
              content: "+";
            }
          }
          &[aria-expanded="true"] {
            color: theme-color("primary");
            &:after {
              content: "\2013";
            }
          }
        }
      }
    }
    &-body {
      padding: 0px 0 10px 0;
    }
  }
}

//cx-accordion is an alterative version of the accordion
//media queries are to be added in each component
//ex. anonymus consent dialog
.cx-accordion {
  margin: 5px auto;
  line-height: 1.2;
  list-style-type: none;
  padding: 0;

  .cx-accordion-item {
    border: 1px solid var(--cx-color-light);
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;

    .cx-accordion-tab {
      background-color: var(--cx-color-inverse);
      &:hover {
        cursor: pointer;
      }
      .cx-accordion-title {
        padding-top: 15px;
        padding-inline-end: 15px;
        padding-bottom: 15px;
        padding-inline-start: 50px;
        display: block;
        position: relative;
        font-weight: 600;
      }
    }

    .cx-accordion-tabpanel {
      transition: height 0.3s ease-out;
      height: 0;
      overflow: hidden;
      background-color: var(--cx-color-inverse);

      .cx-accordion-content {
        padding-top: 0;
        padding-inline-end: 40px;
        padding-bottom: 10px;
        padding-inline-start: 40px;
      }
    }
  }
}
.accordion .card-header .expand-details {
  font-size: 12px;
  padding-right: 15px;
  position: relative;
  border-bottom: none;
  color: #5b646e;
  font-family: "NotoSans", sans-serif;
  font-weight: bold;
}

cx-page-layout.ContentPage1Template {
  margin: 0px 0px 40px 0px;
  display: inline-flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
  padding: 0px;
}

cx-page-layout cx-page-slot.FAQ-left {
  min-height: 1px;
  width: 66%;
  flex: auto;
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  cx-page-layout cx-page-slot.FAQ-left {
    min-height: 1px;
    width: 100%;
    flex: auto;
    min-width: 100%;
  }

  cx-page-layout cx-page-slot.ContentSlotFAQ {
    flex: auto;
    background: white;
    min-width: 100%;
    width: 100%;
  }

  cx-page-layout cx-page-slot.Content-contactus {
    flex: auto;
    background: white;
    min-width: 100%;
    width: 100%;
  }

  cx-page-layout cx-page-slot.Content-contactus a {
    color: #007bff;
  }

  cx-page-layout.ContentPage1Template {
    margin: 0px 0px 40px 0px;
    display: block;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  cx-page-layout cx-page-slot.FAQ-left {
    min-height: 1px;
    width: 66%;
    flex: auto;
    min-width: 66%;
  }
  cx-page-layout cx-page-slot.Content-contactus {
    flex: auto;
    background: white;
    min-width: 32%;
  }

  cx-page-layout cx-page-slot.Content-contactus a {
    color: #007bff;
  }

  cx-page-layout cx-page-slot.ContentSlotFAQ {
    flex: auto;
    background: white;
    min-width: 32%;
  }

  cx-page-layout.ContentPage1Template {
    margin: 0px 0px 40px 0px;
    display: inline-flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
}

cx-page-layout cx-page-slot.ContentSlotFAQ {
  flex: auto;
  background: white;
  padding-left: 20px;
  max-width: 32%;
}

cx-page-layout cx-page-slot.Content-contactus {
  flex: auto;
  background: white;
  padding-left: 20px;
}

cx-page-layout cx-page-slot.Content-contactus a {
  color: #007bff;
}

.order-info p {
  padding: 20px 0px 0px 0px;
}
app-faq-title .left-section {
  background-color: #ffffff;
  padding: 30px 20px 0px 40px;
  border-right: 4px solid #edf6fb;
}
.faq p {
  margin-bottom: 0;
  font-size: 14px;
}
.wellc_accor h6 {
  margin: 20px 0;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
}
.faq .sanofi-accordion {
  margin-top: 8px;
}
.faq .panel-group .panel:first-child > .panel-heading {
  border-top: 1px solid #ddd;
}
.faq .sanofi-accordion .panel-heading {
  padding-left: 0;
}
.sanofi-accordion .panel-heading {
  padding: 13px;
}
.sanofi-accordion .panel-heading .firstLevel-acc {
  font-size: 12px;
  padding-right: 15px;
  position: relative;
  border-bottom: none;
}
.sanofi-accordion .panel-heading .firstLevel-acc label {
  font-family: "NotoSansBold", sans-serif;
  margin: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-bottom: 1px solid #ddd;
}
.panel-group .panel-heading + .panel-collapse > .list-group,
.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #ddd;
}
.wellc_accor .sanofi-accordion .panel-body {
  font-size: 12px;
  line-height: 18px;
}
.panel-body {
  padding: 15px;
  ol {
    padding: 12px 17px;
    li {
      padding: 0 0 13px 0;
      p {
        font-size: 12px;
      }
    }
  }
}
