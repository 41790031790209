.patientEducation-vertical {
  width: 20%;
  background-color: white;
  padding-left: 40px;
  float: left;
  min-height: 450px !important;
  border-right: 4px solid #edf6fb;
  min-width: 21%;
  display: flex;
  flex-direction: column;
  flex: 0;
}
.patientEducation-vertical .row .menu-content {
  width: 100%;
}
.patientEducation-vertical .row .menu-content .list .title {
  color: #337ab7 !important;
  text-decoration: none;
  font-size: 12px;
  font-family: "NotoSansBold", sans-serif;
  padding-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
}
.patientEducation-vertical .row .menu-content .list .title:hover {
  text-decoration: underline !important;
  color: #05579d !important;
}
.patientEducation-vertical .row .menu-content .menu {
  color: #2a343f !important;
  font-size: 12px;
  font-family: "NotoSansBold", sans-serif;
  border-bottom: 1px solid #edf6fb;
  padding-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
  padding-top: 20px;
}
.patient_education .left-section .vaccine-title {
  color: #5b646e;
  font-family: "NotoSansBold", sans-serif;
  margin-top: 22px;
}

.patientEducation-Horizontal {
  background-color: white;
  min-height: 450px !important;
  display: block;
  flex-direction: column;
  flex: 1;
}
.patientEducation-vertical .menu-content .list {
  margin-top: 20px;
}
cx-page-layout .sanofiResourcesPageTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .patientEducation-Horizontal {
    // width: 75%;
    background-color: white;
    min-height: 300px;
  }

  .patientEducation-vertical {
    width: 16% !important;
    background-color: transparent !important;
    padding-left: 0px !important;
    float: left !important;
    min-height: 20px !important;
    border-right: 4px solid #edf6fb !important;
    margin: 0px 0px 0px 29px;
  }
 
    .sanofiResourcesPageTemplate {
      display: block !important;
      flex-direction: column;

  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .sanofiResourcesPageTemplate {
    display: flex;
    flex-direction: column;
  }
  .dropdown-list{
    display: block;
  }
  .patientEducation-Horizontal {
    width: 100%;
    background-color: white;
    min-height: 450px !important;
  }

  .patientEducation-vertical {
    //  display: none;
    width: 100% ;
    background-color: transparent ;
    padding-left: 0px ;
    float: left ;
    min-height: 20px !important;
    border-right: 4px solid #edf6fb ;
  }
}

