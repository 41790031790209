
// colours
$color-iron: #d4d9de;
$color-medium-teal-blue: #0057a6;
$color-white: #fff;
$color-gunmetal: #2a343f;
$color-moonstone-blue: #73aed3;
$color-ebony-clay: #212738;
$color-shuttle-grey: #5b646e;
$color-mirage: #13181d;
$color-casper: #b1bdc4;
$color-bluish: #337ab7;
$color-catskill-white: #edf6fb;

$color-black: #242424;
$color-black-500: #212121;

$color-blue-dark: #11155c;
$color-blue-darker: #1f1161;
$color-blue-dark-shade1: #6c749e;
$color-blue-dark-shade2: #8c8eb1;

$color-blue-light: #4a90e2;
$color-blue-light-shade1: #f5f8fb;
$color-blue-lighter: #7abfe5;
$color-blue-cyan: #3f7bc0;
$color-blue-cyan-darker: #376ca9;
$color-blue-cyan-darker-light: #0085cf;
$color-blue-cyan-lighter: #1877f2;
$color-blue-cyan-lighter-dark: #186cd8;

$color-green-mint: #70c593;
$color-green: #6aa334;
$color-green-dark: #598a2c;
$color-green-darker: #4f7a26;
$color-green-a11y: #49850a;
$color-green-light: #7fc23f;
$color-green-lighter: #f3fbea;
$color-green-cyan: #006f47;

$color-orange: #f5a623;
$color-orange-dark: #f4742b;
$color-orange-light: #ffd999;
$color-yellow: #ffdd00;
$color-yellow-light: #faff00;

$color-pink: #e8308a;
$color-pink-light: #f097ba;
$color-red: #d0021b;

$color-gray-a125: #707070;
$color-gray-a110: #747474;
$color-gray-a100: #767676;

$color-gray-500: #333333;
$color-gray-400: #4a4a4a;
$color-gray-380: #5f5252;
$color-gray-350: #757575;
$color-gray-325: #949494;
$color-gray-310: #979797;
$color-gray-300: #999999;
$color-gray-280: #c4bcc1;
$color-gray-275: #c2c2c2;
$color-gray-250: #d2d2d2;
$color-gray-245: #dfdfdf;
$color-gray-225: #d8d8d8;
$color-gray-200: #e0e0e0;
$color-gray-190: #e9e9e9;
$color-gray-175: #e7e7e7;
$color-gray-170: #e4e4e4;
$color-gray-165: #e3e5e6;
$color-gray-150: #eaeaea;
$color-gray-125: #f2f2f2;
$color-gray-110: #f4f4f4;
$color-gray-100: #f5f5f5;
$color-gray-75: #f8f8f8;
$color-gray-70: #f9f9f9;
$color-gray-50: #fdfdfd;

// brand colours
$color-brand-primary: $color-blue-dark;
$color-brand-secondary: $color-green;
$color-brand-secondary-light: $color-green-light;

// primary button
$color-button-primary: $color-green;
$color-button-primary-hover: $color-green-dark;
$color-button-primary-active: $color-green-darker;

// secondary button
$color-button-secondary: $color-green-a11y;
$color-button-secondary-hover: $color-green-dark;
$color-button-secondary-active: $color-green-darker;

// tertiary button
$color-button-tertiary: $color-gray-275;
$color-button-tertiary-hover: $color-black;
$color-button-tertiary-active: $color-black;

// error colour
$color-error: $color-red;

// background colour
$color-global-background: $color-gray-70;

// Font Colour
$global-font-color: $color-black;
