.FAQ-left cx-paragraph .faq-container {
  .child-container-one {
    border-right: 4px solid #edf6fb;
    padding: 30px 40px;
    width: 42%;
  }
  h2 {
    font-size: 20px;
  }
  h2,h3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 12px;
    font-family: NotoSansBold,sans-serif;
    border-top: 1px solid#ddd;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    line-height: 1.6;
  }
  p {
    font-size: 14px;
  }
  ol {
    padding-left: 30px;
  }
  li {
    padding-bottom: 13px;
    p {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}
