$footer-two-columns-switch: xlarge;

@use "meta" as *;
@use "../library" as *;

cx-page-layout.footer {
  background: $color-gunmetal;

  cx-page-slot {
    @extend .o-container;
    display: flex;
    flex-wrap: wrap;
    padding: rem-calc(20px);

    > cx-banner:nth-child(1) {
      flex: 40%;
      display: flex;
      align-items: center;
    }

    > cx-footer-navigation:nth-child(2) {
      flex: 100%;
      padding: rem-calc(20px 0);
      @include breakpoint(xlarge) {
        flex: 60%;
      }
    }

    > cx-banner:nth-child(3) {
      border-top: rem-calc(1px) solid $color-mirage;
      padding: rem-calc(20px 0);
      flex: 100%;
      @include breakpoint(xlarge) {
        flex: 10%;
        padding: 0;
      }
    }

    > cx-paragraph:nth-child(4) {
      border-top: 0;
      flex: 90%;
      display: flex;
      align-items: center;
      padding: rem-calc(20px 10px);

      @include breakpoint(xlarge) {
        border-top: rem-calc(1px) solid $color-mirage;
        padding: rem-calc(20px 10px);
      }

    }

    cx-footer-navigation {
      cx-navigation-ui {
        justify-content: start;

        & > nav {
          padding: rem-calc(0px 20px 0px 25px);
          border-left: rem-calc(1px) solid $color-mirage;
        }

        .childs {
          nav {
            margin-bottom: rem-calc(15px);
          }
        }

        // elements inside footer menu
        nav {
          margin: 0;
        }

        h5 {
          text-transform: capitalize;
          @include brand-font-bold();
          font-size: rem-calc(14px);
          color: $color-moonstone-blue;
          margin-bottom: rem-calc(20px);
          padding: rem-calc(0px);
        }
      }

      // normal url in footer
      cx-generic-link > a {
        font-size: rem-calc(14px);
        color: #fff;
      }
    }

    cx-banner:nth-child(3) {
      display: inline-block;
      padding: rem-calc(20px);

      // sanofi logo
      cx-media img {
        height: rem-calc(22px);
        background: none;
      }
    }
  }

  //for PharmaDrive logo placement
  cx-banner:nth-child(1) > cx-generic-link cx-media img {
    background: transparent;
    width: 50%;
    padding: rem-calc(20px 20px 0px 25px);

    @include breakpoint(xlarge) {
      scale: 0.8;
      width: 100%;
    }

  }

  cx-media {
    display: inline-block;
  }

  // copyright text
  cx-paragraph {
    color: $color-casper;
    font-size: rem-calc(12px);
  }
}
