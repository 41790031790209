@use '../01-settings/settings.variables' as *;

@mixin u-margin($name) {
  &-#{$name} {
    &--none {
      margin-#{$name}: $size-none;
    }

    &--xxs {
      margin-#{$name}: $size-xxs;
    }

    &--xs {
      margin-#{$name}: $size-xs;
    }

    &--s {
      margin-#{$name}: $size-s;
    }

    &--n {
      margin-#{$name}: $size-n;
    }

    &--l {
      margin-#{$name}: $size-l;
    }

    &--xl {
      margin-#{$name}: $size-xl;
    }

    &--xxl {
      margin-#{$name}: $size-xxl;
    }
  }
}

@mixin u-margins($name, $first, $second) {
  &-#{$name} {
    &--none {
      margin-#{$first}: $size-none;
      margin-#{$second}: $size-none;
    }

    &--xxs {
      margin-#{$first}: $size-xxs;
      margin-#{$second}: $size-xxs;
    }

    &--xs {
      margin-#{$first}: $size-xs;
      margin-#{$second}: $size-xs;
    }

    &--s {
      margin-#{$first}: $size-s;
      margin-#{$second}: $size-s;
    }

    &--n {
      margin-#{$first}: $size-n;
      margin-#{$second}: $size-n;
    }

    &--l {
      margin-#{$first}: $size-l;
      margin-#{$second}: $size-l;
    }

    &--xl {
      margin-#{$first}: $size-xl;
      margin-#{$second}: $size-xl;
    }

    &--xxl {
      margin-#{$first}: $size-xxl;
      margin-#{$second}: $size-xxl;
    }
  }
}

.u-margin {
  &--none {
    margin: $size-none;
  }

  &--xxs {
    margin: $size-xxs;
  }

  &--xs {
    margin: $size-xs;
  }

  &--s {
    margin: $size-s;
  }

  &--n {
    margin: $size-n;
  }

  &--l {
    margin: $size-l;
  }

  &--xl {
    margin: $size-xl;
  }

  &--xxl {
    margin: $size-xxl;
  }

  @include u-margin(bottom);
  @include u-margin(top);
  @include u-margin(left);
  @include u-margin(right);

  @include u-margins(vertical, top, bottom);
  @include u-margins(horizontal, left, right);
}
