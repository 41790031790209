.LoginPageTemplate {
    cx-page-slot.AccountOverviewFinancialsSlot.has-components {
      max-width: 1380px;
      padding-top: 0;
    }    
  }
  
  app-account-overview {
    min-width: 100% !important;
    margin: 0 !important;
  }
