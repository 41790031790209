@use '../01-settings/settings.variables' as *;

@mixin u-padding($name) {
  &-#{$name} {
    &--xxs {
      padding-#{$name}: $size-xxs;
    }

    &--xs {
      padding-#{$name}: $size-xs;
    }

    &--s {
      padding-#{$name}: $size-s;
    }

    &--n {
      padding-#{$name}: $size-n;
    }

    &--l {
      padding-#{$name}: $size-l;
    }

    &--xl {
      padding-#{$name}: $size-xl;
    }

    &--xxl {
      padding-#{$name}: $size-xxl;
    }
  }
}

@mixin u-paddings($name, $first, $second) {
  &-#{$name} {
    &--xxs {
      padding-#{$first}: $size-xxs;
      padding-#{$second}: $size-xxs;
    }

    &--xs {
      padding-#{$first}: $size-xs;
      padding-#{$second}: $size-xs;
    }

    &--s {
      padding-#{$first}: $size-s;
      padding-#{$second}: $size-s;
    }

    &--n {
      padding-#{$first}: $size-n;
      padding-#{$second}: $size-n;
    }

    &--l {
      padding-#{$first}: $size-l;
      padding-#{$second}: $size-l;
    }

    &--xl {
      padding-#{$first}: $size-xl;
      padding-#{$second}: $size-xl;
    }

    &--xxl {
      padding-#{$first}: $size-xxl;
      padding-#{$second}: $size-xxl;
    }
  }
}

.u-padding {
  &--none {
    padding: 0;
  }

  &--xxs {
    padding: $size-xxs;
  }

  &--xs {
    padding: $size-xs;
  }

  &--s {
    padding: $size-s;
  }

  &--n {
    padding: $size-n;
  }

  &--l {
    padding: $size-l;
  }

  &--xl {
    padding: $size-xl;
  }

  &--xxl {
    padding: $size-xxl;
  }

  @include u-padding(bottom);
  @include u-padding(top);
  @include u-padding(left);
  @include u-padding(right);

  @include u-paddings(vertical, top, bottom);
  @include u-paddings(horizontal, left, right);
}
