@use 'sass:math';
@use '../02-tools/_tools.functions';

// breakpoint mappings
$breakpoints: (
  small: 0,
  medium: 568px,
  large: 768px,
  xlarge: 1024px,
  xxlarge: 1280px,
  xxxlarge: 1440px,
  xxxxlarge: 1600px,
  xxxxxlarge: 1660px,
  max: 1920px,
);

$tablet-breakpoint: large;
$major-shift-breakpoint: xxlarge;

@mixin breakpoint($value) {
  $bpm: map-get($breakpoints, $value);
  @if ($bpm != null) {
    @media screen and (min-width: $bpm) {
      @content;
    }
  } @else {
    $str: bp-to-em($value);
    @if ($str == '') {
      @content;
    } @else {
      @media print, screen and (min-width: $str) {
        @content;
      }
    }
  }
}

@mixin only-tablet() {
  $bp-min: map-get($breakpoints, $tablet-breakpoint);
  $bp-max: map-get($breakpoints, $major-shift-breakpoint);
  @media screen and (min-width: $bp-min) and (max-width: $bp-max) {
    @content;
  }
}

@mixin only-mobile() {
  $bp-max: map-get($breakpoints, $tablet-breakpoint);
  @media screen and (max-width: $bp-max) {
    @content;
  }
}

@mixin mobile-landscape() {
  @media screen and (max-width: 812px) and (orientation: landscape) {
    @content;
  }
}
