@use '../02-tools/tools.functions' as *;
@use '../01-settings/settings.colours' as *;

.c-box {

  &-content {
    padding: rem-calc(25px 15px);
  }

  &-title {
    font-size: rem-calc(16px);
    color: var(--lib-color-box-title);
    background-color: var(--lib-background-box-title);
    text-align: center;
    padding: rem-calc(10px 0);
    margin:0;
  }

}
