.Section2B cx-banner {
  min-height: 410px;
}

.LandingPage2Template cx-page-layout.LandingPage2Template {
  padding-bottom: 0;
}

cx-breadcrumb h1 {
  text-transform: none;
}

.btn {
  border-radius: 0.25rem;
}

.login-details {
  .forgot-text a,
  .new-customer a {
    display: inline;
  }
}

.alert.alert-success{
  display: none;
}
