a,
.a {
  color: var(--lib-color-link);

  &:hover,
  &:focus {
    color: var(--lib-color-link-hover);
    text-decoration: underline;
  }

}

// Use this class, when you want a <button> to be displayed like a link
.a {
  display: inline;
  padding: 0;
  border: 0;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  appearance: none;

  // This is used to link containers
  &--no-styles {
    text-decoration: none;

    &:active {

    }
  }

  &--no-strict-color {
    color: inherit;
  }
}

