@use "../library" as *;

@import "assets/index";
@import "styles/index";
@import "overwrite";
@import "upgrade";

.well.well-quaternary {
  background-color: #f2f2f2;
  color: #19212b;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.well.well-md {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}
.well {
  box-shadow: none;
  border: none;
  margin: 0;
  min-height: 20px;
  border-radius: 0;
}
.well:before,
.well:after {
  content: " ";
  display: table;
}
.well:after {
  clear: both;
}
.row {
  margin-left: -10px;
  margin-right: -10px;
}

.import-csv__file-spec {
  padding-top: 15px;
}
.import-csv__file-spec-item {
  padding-top: 5px;
}
.import-csv__file-upload {
  border-left: 1px solid #cccccc;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 40px;
}
.file-upload {
  margin-top: 15px;
}
.form-group {
  margin-bottom: 15px;
}
.file-upload__wrapper {
  position: relative;
  overflow: hidden;
  margin-right: 20px;
}
.btn-default {
  border: 3px solid;
  color: #ffffff;
  background-color: #47b6b1;
  border-color: #47b6b1;
}
.btn-small {
  padding: 6px 15px;
  font-size: 16px;
  line-height: 1.375;
  border-radius: 3px;
}
.file-upload__file-name {
  font-size: 16px;
  display: inline-block;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  position: relative;
  top: 8px;
}
.import-csv__actions {
  margin-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
}
.pull-right {
  float: right !important;
}
.btn-primary {
  border: 3px solid;
  color: #ffffff;
  background-color: #ec7205;
  border-color: #ec7205;
}
input[type="file"] {
  display: block;
}
.file-upload__input {
  position: absolute;
  right: -3px;
  top: -3px;
  bottom: -3px;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}

.btn-block {
  display: block;
  width: 300px;
}

.sumbit-button {
  margin-bottom: 30px;
}

.alert {
  text-align: var(--cx-text-align, center);
  padding-inline-end: 4rem;
  padding-inline-start: 20px;
  line-height: var(--cx-line-height, 1.5);
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
  padding: 20px 1.25rem;
  margin-bottom: 1rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.closeAccAlert {
  right: 10px;
  color: inherit;
  border: 0;
  font-size: 21px;
  font-weight: 700;
  opacity: 0.2;
}

.alert .close {
  right: 1rem;
  position: var(--cx-position, absolute);
}

.alert-text {
  text-align: center;
  width: 100%;
  font-size: initial;
  background-color: transparent;
}

.template-button {
  background-color: #0057a6;
  border-block-color: #0057a6;
  border-color: #0057a6;
}

.template-button:focus,
.template-button:active,
.template-button:hover {
  background-color: #0057a6;
  border-block-color: #0057a6;
  border-color: #0057a6;
}

.file-upload #chooseFileButton:hover {
  color: #fff;
}
#importButton:hover,
#importButton:focus {
  color: #ffffff;
  background-color: #ec7205;
  border-color: #ec7205;
}
#importButton:active {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #ffffff;
}

.importCartPosition {
  padding-top: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  max-width: 100% !important;
}
.import-csv__file-spec .template-button {
  width: 250px;
  font-size: 15px;
  float: left;
  margin-right: 12px;
  margin-top: 0px;
}
.LoginPageTemplate .import-csv__file-spec .template-button {
  font-size: 14px;
  font-family: "NotoSansBold", sans-serif;
}
.LoginPageTemplate .file-upload #chooseFileButton {
  font-size: 14px;
  padding: 12px 24px;
}
.LoginPageTemplate #chosenfile {
  display: block;
}
.LoginPageTemplate #importButton {
  font-size: 14px;
}
.LoginPageTemplate .import-csv__actions {
  padding-right: 0px;
}
.import_error_3 {
  position: absolute;
  right: 0px;
  bottom: 59px;
  width: 313px;
}
.LoginPageTemplate .import_error_3,
.LoginPageTemplate .import_error_4,
.LoginPageTemplate .import_error_2,
.LoginPageTemplate .import_error_1 {
  position: absolute;
  right: 19px;
  bottom: -47px;
  width: auto;
}
.import_error_4 {
  position: absolute;
  right: 0px;
  bottom: 49px;
  width: 313px;
}
.import_error_1 {
  position: absolute;
  right: 0px;
  bottom: 48px;
  width: 313px;
}
.import_error_2 {
  position: absolute;
  right: 0px;
  bottom: 47px;
  width: 313px;
}

.LoginPageTemplate .importCartPositionForAcc {
  background: white;
  max-width: 64.5%;
  width: 64%;
  padding: 0px;
  margin: 0px 43px 0px 103px;
  display: inline-block;
  margin-left: 23px !important;
}

.LoginPageTemplate .AccountOverviewFinancialsSlot {
  border-bottom: 4px solid #edf6fb;
}

@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .LoginPageTemplate .import-csv__actions {
    padding-right: 54px;
  }
  .import-csv__file-spec ul {
    padding-left: 0px;
  }
  .LoginPageTemplate #chosenfile {
    overflow-wrap: anywhere;
  }
}
