app-overview-important-banner {
  img.header-close.focus {
    width: 16px;
    height: 16px;
    float: right;
    margin-top: 13px;
    cursor: pointer;
  }

  .bdr-blue {
    border-bottom: 4px solid #edf6fb !important;
  }

  .p0 {
    padding: 0 !important;
  }

  .acc-warning-box {
    min-height: 104px !important;
    background-color: #fff !important;
    margin-bottom: 4px !important;
    border-left: 4px solid #0056a6 !important;
  }

  .margin0 {
    margin: 0 !important;
  }

  .nopadding-nomargin {
    padding: 0 !important;
    margin: 0 !important;
    width: 91% !important;
  }

  .acc-warning-box p {
    margin: 18px 0 -2px 28px !important;
    padding: 0 !important;
  }

  #shippingDelays {
    padding-left: 39px !important;
  }

  .acc-warning-box .warning-text {
    font-family: "Poppins-medium", sans-serif !important;
    font-size: 1.67em !important;
    font-weight: 500 !important;
    color: #2a343f !important;
    margin-left: 0px !important;
    background-image: url("../../assets/icons/warning.svg") !important;
    background-repeat: no-repeat !important;
  }

  .infoBanner span {
    padding-left: 35px !important;
  }

  .px0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .list-type-disc {
    list-style-type: inherit !important;
    font-size: 0.6em !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-stretch: normal !important;
    letter-spacing: normal !important;
    color: #5b646e !important;
    padding: 0px 0 0 34px !important;
    font-family: "NotoSans", sans-serif !important;
  }

  .Notify-Symbol {
    display: inline-block !important;
    width: 100% !important;
    margin: 0 0 12px 0 !important;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .acc-warning-box .warning-text {
      display: inline-block !important;
      width: 87% !important;
      font-size: 1.67em !important;
      line-height: 28px !important;
    }
  }

  .acc-warning-box .alert-close {
    padding: 0 13px 0px 0 !important;
    width: 8.33% !important;
  }

  .bannerHide {
    display: none !important;
  }
}
