@use '../02-tools/tools.functions' as *;
@use '../01-settings/settings.colours' as *;

.c-button {
  font-size: rem-calc(14px);
  text-transform: capitalize;
  line-height: 1.4;
  padding: rem-calc(10px 30px);
  height: rem-calc(48px);
  opacity: 1;
  letter-spacing: rem-calc(2px);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &--primary {
    border: rem-calc(2px) solid var(--lib-color-primary);
    background: var(--lib-color-primary);
    color:var(--lib-color-white);

    &:hover {
      background: var(--lib-color-white);
      color: var(--lib-color-primary);
    }
  }

}

